import { ScrollbarPlugin } from 'smooth-scrollbar';

class LockPlugin extends ScrollbarPlugin {
	static pluginName = 'lock';

	static defaultOptions = {
		open: false,
	};

	transformDelta(delta, event) {
		return this.options.open ? { x: 0, y: 0 } : delta;
	}
}

export default LockPlugin;

// Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);

// usage
// const scrollbar = Scrollbar.init(elem);

// onModalOpen  -> scrollbar.updatePluginOptions('lock', { open: true });
// onModalClose -> scrollbar.updatePluginOptions('lock', { open: false });
