const seo = (() => {

    let init = () => {
        _setupListeners();
    }

    let _setupListeners = () =>
    {
        // Нажатие на телефон
        $(document).on('click', '[data-component="contact-block__link-phone"]', function() {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('event', 'nazhatie-na-nomer-telefona');
        });

        // Нажатие на email
        $(document).on('click', '[data-component="contact-block__link-email"]', function() {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('event', 'nazhatie-na-email');
        });
    }

    return {
        init,
    }

})();

seo.init();
