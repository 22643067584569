const managementPopup = (() => {

    let init = () => {
        _setupListeners();
    }

    let _setupListeners = () => {
        $(document).on('click', '[data-action="showManagementPopup"]', function() {
            let $btn = $(this),
                existData = $btn.data('existData');
            app.showModalWindow(
                'front.modals.management',
                ($modal) => {
                    setTimeout(() => {
                        console.log("time");
                    }, 300);
                    app.initSelectFn();
                },
                existData,
                null);
        });
    }

    return {
        init,
    }

})();

managementPopup.init();
