import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Scrollbar from 'smooth-scrollbar';
import SoftScroll from './soft-scroll';
import LockScroll from './lock-scroll';

gsap.registerPlugin(ScrollTrigger);
Scrollbar.use(SoftScroll, LockScroll);

const scroll = (() => {
	window.ScrollTrigger = ScrollTrigger;

	// Инициализация
	let init = () => {
		const mq = window.matchMedia('(min-width: 1024px)');
		if (!mq.matches) return;

		const scroller = document.querySelector('.scroller');

		window.bodyScrollBar = Scrollbar.init(scroller, {
			continuousScrolling: false,
			damping: 0.06,
		});

		window.ScrollTrigger.scrollerProxy('.scroller', {
			scrollTop(value) {
				if (arguments.length) {
					window.bodyScrollBar.scrollTop = value;
				}
				return window.bodyScrollBar.scrollTop;
			},
			getBoundingClientRect() {
				return {
					top: 0,
					left: 0,
					width: window.innerWidth,
					height: window.innerHeight,
				};
			},
		});

		window.ScrollTrigger.defaults({
			scroller: scroller,
		});

		_setupListeners();
	};

	// Навешиванеи событий
	let _setupListeners = () => {
		window.bodyScrollBar.addListener(ScrollTrigger.update);
	};

	// Доступные методы
	return {
		init,
	};
})();

scroll.init();
