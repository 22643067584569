import 'core-js';
import svg4everybody from 'svg4everybody';
import '@fancyapps/fancybox';
import {
	showModalWindow,
	confirmPopup,
	alertPopup,
} from '../import/showModalWindow';
import '../import/removeClassWild';

window.$window = $(window);
window.$document = $(document);
window.$body = $('body');
window.$header = $('.header');
window.windowWidth = $window.width();
window.windowScroll = 0;
window.windowLastScroll = 0;

window.app = {
	showModalWindow,
	confirmPopup,
	alertPopup,
};

let updateScrollState = (event) => {
	windowWidth = $window.width();

	if (window.bodyScrollBar) {
		windowScroll = event ? event.offset.y : '';
	} else {
		windowScroll = $window.scrollTop();
	}

	if (windowScroll > 16) {
		if (!$body.hasClass('page_scrolled')) {
			$body.addClass('page_scrolled');
		}
	} else {
		if ($body.hasClass('page_scrolled')) {
			$body.removeClass('page_scrolled');
		}
	}

	if (windowScroll > 16) {
		if (windowScroll > windowLastScroll) {
			$body.addClass('page_scroll-down');
			$body.removeClass('page_scroll-up');
		} else if (windowScroll < windowLastScroll) {
			$body.addClass('page_scroll-up');
			$body.removeClass('page_scroll-down');
		}
	}

	windowLastScroll = windowScroll;
};

$window.on('load', function () {
	$body.addClass('page_loaded');
	$body.removeClass('preload');
	svg4everybody();
});

$window.on('load resize', function () {
	updateScrollState();
});

if (window.bodyScrollBar) {
	bodyScrollBar.addListener(_throttle(updateScrollState, 100));
} else {
	$window.on('scroll', _throttle(updateScrollState, 100));
}
